export default {
  Kurdish: {
    month: "مـانگ",
    year: "سـاڵ",
    phone_number: "ژمارە مۆبایل",
    search: "گـەڕان",
    staffs: "ستافەکان",
    give_salary: "پێدانی مووچە",
    create_monthly_receipt: "دروستکردنی وەصـڵی مانگانە",
    report: "ڕاپۆرتی",
    id: "کۆد",
    employee_name: "نـاو",
    staff: "ستاف",
    salary_type: "جۆری مووچە",
    monthly_salary: "مووچەی مانگانە",
    daily_salary: "مووچەی ڕۆژانە",
    hour_salary: "مووچەی کاتژمێر/کاتی زیادە",
    total_worked_hours: "کـۆی کارکردن (کاتژمێر)",
    total_overtime: "کـۆی کاتی زیادە (کاتژمێر)",
    total_not_worked_hours: "کـۆی کارنەکردن (کاتژمێر)",
    total_present: "ڕۆژانی ئامادەبوو",
    total_off: "ڕۆژانی ئۆف (پشوو)",
    total_absent: "ڕۆژانـی نـەهاتوو",
    one_dollar_price: "نرخی 1 دۆلار",
    total_fine_salary: "کـۆی بـڕی سـەرپێچـی",
    total_not_work_salary: "کـۆی بـڕی کاتی کارنەکردن",
    total_absent_salary: "کـۆی بـڕی ڕۆژانی نەهاتن",
    total_overtime_salary: "کـۆی بـڕی کاتی زیادە",
    food_money: "پارەی خواردن",
    transport_money: "کرێی هاتووچۆ",
    calculated_salary_until_end_of_month:"بـڕی مـووچەی هەژمارکراو تا کۆتایی مانگ",
    calculated_salary_until_now: "بـڕی مـووچەی هەژمارکراو بەپێی سیستم تا ئێستا",
    gived_salary_until_now: "بـڕی مـووچەی وەرگیراو تا ئێستا",
    remain_salary_until_end_of_month: "بـڕی مـووچەی مـاوە تا کۆتایی مانگ",
    remain_salary_until_now: "بڕی مووچەی ماوە تا ئێستا",
    gived_amount: "بڕی مووچە",
    gived_date: "بەرواری وەرگرتن",
    list_id: "ژ.لـیست",
    work_date: "بـەرواری کارکردن",
    location: "شوێن",
    worked_hours: "کارکردن (کاتژمێر)",
    overtime_hours: "زیادە (کاتژمێر)",
    fine_reason: "هۆی سەرپێچی",
    fine: "بـڕی سەرپێچی",
    status: "دۆخ",
    monthly: "مانگانە",
    daily: "ڕۆژانە",
    present: "ئامادەبوو",
    absent: "نەهاتوو",
    off: "ئۆف(پشوو)",
    employee_gived_all_money: "ئەم کارمەندە هەموو بڕە پارەی مانگەکەی وەرگرتووە",
    max_give_salary: "زۆرترین بڕی پێدانی مووچە",
    collimate_give_salary: "بۆ ڕێککردنەوەی مووچە",
    daily_collimate: "تا 1000 دینار ڕێگەپێدراوە بۆ کارمەندی ڕۆژانە",
    monthly_collimate: "تا 1 دۆلار ڕێگەپێدراوە بۆ کارمەندی مانگانە"
  },
  English: {
    month: "Month",
    year: "Year",
    phone_number: "Phone Number",
    search: "Search",
    staffs: "Staffs",
    give_salary: "Give Salary",
    create_monthly_receipt: "Create monthly receipt",
    report: "Report of",
    id: "ID",
    employee_name: "Full name",
    staff: "Staff",
    salary_type: "Salary type",
    monthly_salary: "Monthly salary",
    daily_salary: "Daily salary",
    hour_salary: "Hour salary\\Overtime",
    total_worked_hours: "Total worked (hour)",
    total_overtime: "Total overtime (hour)",
    total_not_worked_hours: "Total not worked (hour)",
    total_present: "Total present",
    total_off: "Total off",
    total_absent: "Total absent",
    one_dollar_price: "Price of 1 dollar",
    total_fine_salary: "Total amount of fine",
    total_not_work_salary: "Total amount of not worked hours",
    total_absent_salary: "Total amount of absent days",
    total_overtime_salary: "Total amount of overtime",
    food_money: "Food money",
    transport_money: "Transport money",
    calculated_salary_until_end_of_month:"Calculated salary until end of month",
    calculated_salary_until_now: "Calculated salary until now",
    gived_salary_until_now: "Total gived salary until now",
    remain_salary_until_end_of_month: "Total remain salary until end of month",
    remain_salary_until_now: "Total remain salary until now",
    gived_amount: "Gived amount",
    gived_date: "Gived date",
    list_id: "List ID",
    work_date: "Work date",
    location: "Location",
    worked_hours: "Worked (hour)",
    overtime_hours: "Overtime (hour)",
    fine_reason: "Fine reason",
    fine: "Fine",
    status: "Status",
    monthly: "Monthly",
    daily: "Daily",
    present: "Present",
    absent: "Absent",
    off: "Off",
    employee_gived_all_money: "This employee has gived all monthly salary",
    max_give_salary: "Maximum amount of give salary",
    collimate_give_salary: "To collimate salary",
    daily_collimate: "It has enable until 1000 IQD",
    monthly_collimate: "It has enable until 1 Dollar"
  },
  Arabic: {
    month: "شهـر",
    year: "عـام",
    phone_number: "رقم موبایل",
    search: "بحـث",
    staffs: "العاملین",
    give_salary: "استلام الراتب",
    create_monthly_receipt: "عمل فاتورة شهرية",
    report: "تقریر",
    id: "رقـم",
    employee_name: "اسـم",
    staff: "ستاف",
    salary_type: "نوع الراتب",
    monthly_salary: "راتب شهري",
    daily_salary: "الراتب اليومي",
    hour_salary: "الراتب الساعة / العمل الإضافي",
    total_worked_hours: "إجمالي العمل (ساعات)",
    total_overtime: "الإجمالي وقت إضافي (ساعات) ",
    total_not_worked_hours: "إجمالي وقت التوقف (ساعات)",
    total_present: "كانت الأيام حاضرا",
    total_off: "أيام العطلة",
    total_absent: "الأيام الغیاب",
    one_dollar_price: "السعر 1 دولار",
    total_fine_salary: "مجموع الغرامة",
    total_not_work_salary: "إجمالي وقت التعطل",
    total_absent_salary: "إجمالي أيام الغياب",
    total_overtime_salary: "إجمالي الوقت الإضافي",
    food_money: "أموال الطعام",
    transport_money: "تكلفة النقل",
    calculated_salary_until_end_of_month:"مبلغ الراتب المحتسب بنهاية الشهر",
    calculated_salary_until_now: "مبلغ الراتب المحسوب حسب النظام حتى الآن",
    gived_salary_until_now: "مقدار الراتب المستلم حتى الآن",
    remain_salary_until_end_of_month: "يبقى مقدار الراتب حتى نهاية الشهر",
    remain_salary_until_now: "مبلغ الراتب لا يزال حتى الآن",
    gived_amount: "مبلغ الراتب",
    gived_date: "تاريخ استلام",
    list_id: "ر. قائمة",
    work_date: "تاريخ العمل",
    location: "موقع",
    worked_hours: "(ساعات) العمل",
    overtime_hours: "إضافي (ساعات)",
    fine_reason: "سبب الغرامة",
    fine: "مبلغ الغرامة",
    status: "حالة",
    monthly: "شهريا",
    daily: "اليومي",
    present: "كان حاضرا",
    absent: "غیاب",
    off: "يوم الاجازة (عطلة)",
    employee_gived_all_money: "حصل الموظف على جميع أموال الشهر",
    max_give_salary: "الحد الأقصى للراتب",
    collimate_give_salary: "لضبط الراتب",
    daily_collimate: "يسمح بحد أقصى 1000 دينار للموظفين المياومين",
    monthly_collimate: "يُسمح بما يصل إلى 1 دولار للموظفين شهريًا"
  }
};
