<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-1 col-xl-1 mb-2"> {{ givedSalaryLanguage[language].month }}: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <label class="mt-1 col-xl-1 mb-2"> {{ givedSalaryLanguage[language].year }}: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year">
                        <label class="mt-1 col-xl-2 mb-2"> {{ givedSalaryLanguage[language].phone_number }}: </label>
                        <input type="text" maxlength="11" class="form-control col-xl-2 col-md-4 mb-2" v-model="phone">
                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" v-on:click="search()"> {{ givedSalaryLanguage[language].search }} <i class="fa fa-search"></i> </button><hr>
                    </div>
                    <hr class="no-print">
                    <button class="no-print btn btn-info btn-block mb-3" data-toggle="collapse" data-target="#staffs"> {{ givedSalaryLanguage[language].staffs }} <i class="fa fa-arrow-down"></i> </button>
                    <div class="collapse" id="staffs">
                        <div class="row no-print">
                            <label class="col-sm-1 mt-1"> {{ givedSalaryLanguage[language].staffs }}: </label>
                            <select v-model="staff_id" class="form-control col-sm-3 mb-2">
                                <option value=""></option>
                                <option v-for="staff in staffs.filter(obj => {
                                        if(user.type == 'E') return true;
                                        else return  obj.special_staff == showSpecialStaffs
                                    })" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                            </select>
                            <span class="col-1" v-if="user.type == 'A' || user.type == 'K'">
                                <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                            </span>
                            <div class="col-sm-3 mb-2" v-if="staff_id">
                                <button class="btn btn-success btn-block" id="btn-actived-employees" v-on:click="get_actived()"> Actived Employees </button>
                            </div>
                            <div class="col-sm-3 mb-2" v-if="staff_id">
                                <button class="btn btn-danger btn-block" id="btn-deactived-employees" v-on:click="get_deactived()"> Dectived Employees </button>
                            </div>
                        </div>
                        <hr class="no-print">
                        <div class="row no-print">
                            <div class="col-sm-2 mb-2" v-for="emp in employees" :key="emp.phone">
                                <button class="btn btn-primary btn-block" v-on:click="emp_id = emp.emp_id"> {{ emp.full_name }} </button>
                            </div>
                        </div>
                    </div>
                    
                    <hr class="no-print" v-if="user.type != 'K'">
                    <div class="no-print row" v-if="user.type != 'K'">
                        <label class="mt-1 mx-2"> 1 $ =  </label>
                        <input type="number" min="0" class="form-control col-xl-1 text-center" v-model="dollar_price" v-bind:readonly="gs_id != null">
                        <label class="mt-1 mx-2"> IQD </label>
                    </div>
                    <hr class="no-print">
                    <div class="row px-1 py-1 mb-2" id="report" >
                        <div class="col-12 py-1 multi-language no-print-border" style="border: 1px solid; overflow-x: auto;" >
                            <img v-bind:src="employee.personal_image ? $image_path + '/employee/personal_image/' + employee.personal_image : ''" width="12%" style="border: 1px solid;" class="mb-3 float-right">

                            <template v-if="!user.en_id && user.type != 'K'">
                                <button class="btn btn-success btn-multi-language ml-1 float-right no-print" v-if="gs_id != null" data-toggle="modal" data-target="#mdl_give_detail"> {{ givedSalaryLanguage[language].give_salary }} &nbsp; <i class="fa fa-check-circle"></i> </button>
                                <button class="btn btn-primary btn-multi-language ml-1 float-right no-print" v-else v-on:click="create_monthly_receipt()"> {{ givedSalaryLanguage[language].create_monthly_receipt }} &nbsp; <i class="fa fa-plus"></i> </button>
                            </template>
                            <span class="mx-5 float-right" v-if="debt < 0"> The Employee has {{ (debt * -1).toLocaleString() }} {{ employee.salary_type == 'Daily' ? 'IQD' : '$' }} </span>
                            <span class="mx-5 float-right" v-else> The Company has {{ (debt).toLocaleString() }} {{ employee.salary_type == 'Daily' ? 'IQD' : '$' }} </span>
                            <span> {{ givedSalaryLanguage[language].report }}:  {{ month + '/' + year }} </span>  
                            <br><br>
                            <span style="width: 20%; display: inline-block;"> {{ givedSalaryLanguage[language].id }}:  {{ employee.emp_id }} </span> 
                            <span style="width: 25%; display: inline-block;"> {{ givedSalaryLanguage[language].phone_number }}:  {{ phone }} </span> 
                            <br><br> 
                            <span> {{ givedSalaryLanguage[language].employee_name }}:  {{ employee.full_name }} </span>
                            <br><br>
                            <span> {{ givedSalaryLanguage[language].staff }}:  {{ employee.staff_name }} </span>  
                            <br><br>
                            <span style="width: 21%; display: inline-block;"> {{ givedSalaryLanguage[language].salary_type }}:  {{ employee.salary_type == "Monthly" ? givedSalaryLanguage[language].monthly : givedSalaryLanguage[language].daily }} </span>
                            <span style="width: 25%; display: inline-block;">  {{ givedSalaryLanguage[language].daily_salary }}: 
                                <span> {{ employee.daily_salary | money_filter }}  </span>
                                <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                <span v-else> IQD </span>
                            </span> 
                            <span style="width: 25%; display: inline-block;">  {{ givedSalaryLanguage[language].monthly_salary }}: 
                                <span> {{ employee.monthly_salary | money_filter }}  </span>
                                <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                <span v-else> IQD </span>
                            </span> 
                            <span style="width: 29%; display: inline-block;">{{ givedSalaryLanguage[language].hour_salary }}: 
                                <span> {{ employee.hour_salary | money_filter }}  </span>
                                <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                <span v-else> IQD </span>
                            </span> 
                            <hr>
                            <span style="width:33%; display: inline-block;"> {{ givedSalaryLanguage[language].total_worked_hours }} : 
                                <span> {{ employee.total_worked_hour }}  </span>
                            </span>
                            <span style="width:33%; display: inline-block;"> {{ givedSalaryLanguage[language].total_overtime }} : 
                                <span> {{ employee.total_overtime }}  </span>
                            </span>
                            <span style="width:33%; display: inline-block;"> {{ givedSalaryLanguage[language].total_not_worked_hours }} : 
                                <span> {{ employee.total_h_not_work }}  </span>
                            </span>
                            <br><br>
                            <span style="width:33%; display: inline-block;"> {{ givedSalaryLanguage[language].total_present }} : 
                                <span> {{ employee.total_present }}  </span>
                            </span>
                            <span style="width:33%; display: inline-block;"> {{ givedSalaryLanguage[language].total_off }} : 
                                <span> {{ employee.total_off }}  </span>
                            </span>
                            <span style="width:33%; display: inline-block;"> {{ givedSalaryLanguage[language].total_absent }} : 
                                <span> {{ employee.total_absent }}  </span>
                            </span>
                            
                            <!-- <hr> -->
                            <!-- <div style="width: 70%; display: inline-block;">
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].one_dollar_price }} : 
                                    <span> {{ dollar_price }}  </span>
                                    <span> IQD </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].total_fine_salary }} : 
                                    <span> {{ employee.total_fine | money_filter}}  </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ <i class="fa fa-minus text-danger"></i> </span>
                                    <span v-else> IQD <span v-if="employee.salary_type=='Daily'"> {{ ' (' + (((employee.total_fine / dollar_price) || 0) | money_filter) + ' $)' }} </span> <i class="fa fa-minus text-danger"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].total_not_work_salary }} : 
                                    <span> {{ employee.total_not_work_s | money_filter}}  </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ <i class="fa fa-minus text-danger"></i> </span>
                                    <span v-else> IQD <span v-if="employee.salary_type=='Daily'"> {{ ' (' + (((employee.total_not_work_s / dollar_price) || 0) | money_filter) + ' $)' }} </span> <i class="fa fa-minus text-danger"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].total_absent_salary }} : 
                                    <span> {{ employee.total_apsen_s | money_filter}}  </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ <i class="fa fa-minus text-danger"></i> </span>
                                    <span v-else> IQD <span v-if="employee.salary_type=='Daily'"> {{ ' (' + (((employee.total_apsen_s / dollar_price) || 0) | money_filter) + ' $)' }} </span> <i class="fa fa-minus text-danger"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ 'Total Off' }} : 
                                    <span> {{ employee.total_off_s | money_filter}}  </span>
                                    <span> IQD <i class="fas fa-exclamation-triangle text-warning"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;" class="font-weight-bold"> {{ givedSalaryLanguage[language].total_overtime_salary }} : 
                                    <span> {{ employee.total_o_s | money_filter}}  </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ <i class="fa fa-plus text-success"></i> </span>
                                    <span v-else> IQD <span v-if="employee.salary_type=='Daily'"> {{ ' (' + (((employee.total_o_s / dollar_price) || 0) | money_filter) + ' $)' }} </span> <i class="fa fa-plus text-success"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].food_money }} : 
                                    <span> {{ employee.food_money | money_filter}} </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ <i class="fa fa-plus text-success"></i> </span>
                                    <span v-else> IQD <i class="fa fa-plus text-success"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].transport_money }} : 
                                    <span> {{ employee.transport_money | money_filter}}  </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ <i class="fa fa-plus text-success"></i> </span>
                                    <span v-else> IQD <i class="fa fa-plus text-success"></i> </span>
                                </span>
                                <span style="width:100%; display: inline-block;" v-bind:class="{ 'font-weight-bold': each_days.length >= 28, 'font-weight-normal': each_days.length < 28 }"> {{ givedSalaryLanguage[language].calculated_salary_until_end_of_month }} : 
                                    <span> {{ last_salary | money_filter}} </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                    <span v-else> IQD </span>
                                    <span v-if="employee.salary_type=='Daily'"> {{ ' (' + (((last_salary / dollar_price) || 0) | money_filter) + ' $)' }} </span>
                                </span>
                                <span style="width:100%; display: inline-block;" v-bind:class="{ 'font-weight-bold': each_days.length < 28, 'font-weight-normal': each_days.length >= 28 }"> {{ givedSalaryLanguage[language].calculated_salary_until_now }} : 
                                    <span> {{ now_salary | money_filter}} </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                    <span v-else> IQD </span>
                                    <span v-if="employee.salary_type=='Daily'"> {{ ' (' + (((now_salary / dollar_price) || 0) | money_filter) + ' $)' }} </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].gived_salary_until_now }} : 
                                    <span> {{ employee.gived_salary | money_filter}} </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                    <span v-else> IQD </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].remain_salary_until_end_of_month }} : 
                                    <span v-if="(last_salary - Number(employee.gived_salary)).toFixed(3) > 0"> {{ (last_salary - Number(employee.gived_salary)).toFixed(3) || 0 | money_filter }} </span>
                                    <span v-else> 0 </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                    <span v-else> IQD </span>
                                    <span v-if="employee.salary_type=='Daily' && (last_salary - Number(employee.gived_salary)).toFixed(3) > 0"> {{ ' (' + (((((last_salary - Number(employee.gived_salary)).toFixed(3)) / dollar_price) || 0) | money_filter) + ' $)' }} </span>
                                </span>
                                <span style="width:100%; display: inline-block;"> {{ givedSalaryLanguage[language].remain_salary_until_now }} : 
                                    <span v-if="(now_salary - Number(employee.gived_salary)).toFixed(3) > 0"> {{ (now_salary - Number(employee.gived_salary)).toFixed(3) || 0 | money_filter }} </span>
                                    <span v-else> 0 </span>
                                    <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                    <span v-else> IQD </span>
                                    <span v-if="employee.salary_type=='Daily' && (now_salary - Number(employee.gived_salary)).toFixed(3) > 0"> {{ ' (' + (((((now_salary - Number(employee.gived_salary)).toFixed(3)) / dollar_price) || 0) | money_filter) + ' $)' }} </span>

                                </span>
                                <span style="width: 25%; display: inline-block;"> {{ 'Calculate base 26 days' }} : </span>
                                <span v-if="employee.salary_type == 'Monthly' && each_days.length > 26"> <input type="checkbox" class="mt-2" style="display: inline !important; zoom: 1.3;" v-model="base_26"> </span>

                            </div>
                            <div style="width: 30%; display: inline;" class="btn-multi-language float-right">  
                                <table class="table table-bordered text-dark text-center">
                                    <thead>
                                        <th> {{ givedSalaryLanguage[language].gived_amount }} </th>
                                        <th> {{ givedSalaryLanguage[language].gived_date }} </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="salary in each_give_salary" :key="salary.gsd_id">
                                            <td> 
                                                {{ salary.gived_salary }} 
                                                <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                                <span v-else> IQD </span>
                                            </td>
                                            <td> {{ salary.gived_date | date_filter }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr> -->
                            <table class="table table-bordered text-center text-dark mt-4">
                                <thead>
                                    <th class="p-2" style="width: 7.8%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].list_id }} </th>
                                    <th class="p-2" style="width: 13%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].staff }} </th>
                                    <th class="p-2" style="width: 10%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].work_date }} </th>
                                    <th class="p-2" style=" font-size: 11.5pt !important;"> List Date </th>
                                    <th class="p-2" style=" font-size: 11.5pt !important;"> User </th>
                                    <th class="p-2" style="width: 14%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].location }} </th>
                                    <th class="p-2" style=" font-size: 11.5pt !important;"> Work </th>
                                    <th class="px-0" style="width: 11%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].worked_hours }} </th>
                                    <th class="px-0" style="width: 11%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].overtime_hours }} </th>
                                    <th class="p-2" style="width: 7%; font-size: 11.5pt !important;"> {{ givedSalaryLanguage[language].status }} </th>
                                    
                                </thead>
                                <tbody>
                                    <tr v-for="day in each_days" :key="day.dsl_id" v-bind:class="{ 'bg-danger text-white': day.absent == 1, 'bg-warning text-white': day.absent == 2 }">
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.dsl_id }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.staff_name }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.work_date.slice(0,10) }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1">
                                            {{ day.datetime_list ? new Date(day.datetime_list).toISOString().split('T')[0] : '' }}
                                            {{ day.datetime_list ? new Date(day.datetime_list).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                                        </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.user }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.location }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.work }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.worked_hours }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.overtime }} </td>
                                        <td style="font-size: 11.8pt;" class="px-1"> {{ day.absent | absent_filter(language) }} </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                            <div class="alert alert-primary text-center"> {{ givedSalaryLanguage[language].employee_name }} : {{ employee.full_name }} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <change-pass/>
        
        <div class="modal animate__animated animate__fadeInDown animate__faster" id="mdl_give_detail">
            <div class="modal-dialog text-dark">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ givedSalaryLanguage[language].give_salary }} </h5>
                    </div>
                    <div class="modal-body">
                        <div v-if="employee.gived_salary >= employee.monthly_salary" class="alert alert-primary text-center">
                            {{ givedSalaryLanguage[language].employee_gived_all_money }}
                        </div>
                        <div v-else class="container-fluid">
                            <div class="row mb-2">
                                <label class="col-sm-4 mt-2 text-center"> {{ givedSalaryLanguage[language].gived_date }}:  </label>
                                <label class="form-control col-sm-7 text-center"> {{ new Date().toISOString().split("T")[0] }} </label>
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-4 mt-2 text-center"> {{ givedSalaryLanguage[language].gived_amount }}:  </label>
                                <input type="number" class="form-control col-sm-7 text-center" v-model.lazy="salary">
                                <label v-if="employee.salary_type == 'Monthly'" class="col-sm-1 mt-2"> $ </label>
                                <label v-else class="col-sm-1 mt-2"> IQD </label>
                            </div>
                            <!-- <div class="alert alert-warning text-center">
                                {{ givedSalaryLanguage[language].max_give_salary }} :
                                <span v-if="employee.total_s_by_h_work > employee.total_final_s"> {{ ((Number(employee.total_s_by_h_work) - Number(employee.gived_salary)).toFixed(3) - Number(salary)).toFixed(3)  }} </span> 
                                <span v-else> {{ ((Number(employee.total_final_s) - Number(employee.gived_salary)).toFixed(3) - Number(salary)).toFixed(3)  }} </span> 
                                <label v-if="employee.salary_type == 'Monthly'" class="col-sm-1 mt-2"> $ </label>
                                <label v-else class="col-sm-1 mt-2"> IQD </label>
                            </div>
                            <div class="alert alert-primary multi-language">
                                {{ givedSalaryLanguage[language].collimate_give_salary }} :
                                <br>
                                {{ givedSalaryLanguage[language].monthly_collimate }}
                                <br>
                                {{ givedSalaryLanguage[language].daily_collimate }}
                            </div> -->
                            <button class="btn btn-primary btn-block" id="btn_give_salary" v-on:click="give_salary()"> {{ givedSalaryLanguage[language].give_salary }} &nbsp; <i class="fa fa-dollar-sign fa-sm"></i> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import auth from '../auth';
    import $ from 'jquery';
    import givedSalaryLanguage from '../languages/gived_salary.js';
    import { Bus } from '../Bus.js';
    import showError from '../showError.js';

    export default {
        name: "employeeProfile",
        data() {
            return {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                phone: "",
                gs_id: null,
                salary: 0,
                employee: {
                    emp_id: null,
                    full_name: null,
                    staff_name: null,
                    salary_type: null,
                    monthly_salary: 0,
                    daily_salary: 0,
                    hour_salary: 0,
                    total_worked_hour: 0,
                    total_not_work_s: 0,
                    total_overtime: 0,
                    total_o_s: 0,
                    total_apsen_s: 0,
                    total_absent: 0,
                    total_present: 0,
                    total_fine: 0,
                    total_off: 0,
                    total_off_s: 0,
                    total_s_by_h_work: 0,
                    total_final_s: 0,
                    gived_salary: 0,
                    personal_image: null,
                    food_money: 0,
                    transport_money: 0,
                    cabina_money: 0,
                    expense_money: 0,
                    fine_money: 0,
                    loan_money: 0,
                    accomodation_money: 0,
                    other_expense: 0,
                    other_minus: 0,
                    total_final_s_gui: 0,
                    total_s_by_h_work_gui: 0 
                },
                each_days: [],
                each_give_salary: [],
                dollar_price: 0,
                employees_btn:{
                    full_name:null
                },
                base_26: false,
                staff_id:"",
                staffs: [],
                employees: [],
                givedSalaryLanguage,
                language: "English",
                debt: 0,
                user: {
                    type: '',
                    username: "",
                    en_id: ""
                },
                emp_id: null,
                showSpecialStaffs: "false"
            }
        },
        beforeCreate(){
            auth("give_salary");
        },
        created(){
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user.username = data.username;
                this.user.type = data.type;
                this.user.en_id = data.en_id;
                if(this.user.en_id) {
                    this.$http.post("/staff/getByEngineer/" + this.user.en_id).then(({data}) => {
                        this.staffs = data
                    })
                } else {
                    this.$http.post("/staff/getData").then(({data}) => {
                        this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                    })
                }
            });
            
        },
        mounted() {
            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl");
                    $(".btn-multi-language, img").removeClass("float-right");
                    $(".btn-multi-language, img").addClass("float-left");
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr");
                    $(".btn-multi-language, img").addClass("float-right");
                    $(".btn-multi-language, img").removeClass("float-left");
                }
            });
        },
        methods: {
            search() {
                this.employee.emp_id = null;
                this.employee.full_name = null;
                this.employee.staff_name = null;
                this.employee.salary_type = null;
                this.employee.monthly_salary = 0;
                this.employee.daily_salary = 0;
                this.employee.hour_salary = 0;
                this.employee.total_worked_hour = 0;
                this.employee.total_not_work_s = 0;
                this.employee.total_overtime = 0;
                this.employee.total_o_s = 0;
                this.employee.total_apsen_s = 0;
                this.employee.total_absent = 0;
                this.employee.total_present = 0;
                this.employee.total_fine = 0;
                this.employee.total_off = 0;
                this.employee.total_off_s = 0;
                this.employee.total_s_by_h_work = 0;
                this.employee.total_final_s = 0;
                this.employee.gived_salary = 0;
                this.employee.personal_image = null;
                this.employee.food_money = 0;
                this.employee.transport_money = 0;
                this.employee.cabina_money = 0;
                this.employee.expense_money = 0;
                this.employee.fine_money = 0;
                this.employee.loan_money = 0;
                this.employee.accomodation_money = 0;
                this.employee.other_expense = 0;
                this.employee.other_minus = 0;
                this.each_days = [];
                this.each_give_salary = [];
                this.dollar_price = 0;
                this.debt = 0;
                
                this.$http.post('/employee/getEmployeeInfo/' + this.emp_id + '/' + this.month + '/' + this.year).then(({data})=>{
                    if(!data.employee){
                        this.$alert("هیچ داتایەک نەدۆزرایەوە", "", "error");
                        this.employee.emp_id = null;
                        this.employee.full_name = null;
                        this.employee.staff_name = null;
                        this.employee.salary_type = null;
                        this.employee.monthly_salary = 0;
                        this.employee.daily_salary = 0;
                        this.employee.hour_salary = 0;
                        this.employee.total_worked_hour = 0;
                        this.employee.total_not_work_s = 0;
                        this.employee.total_overtime = 0;
                        this.employee.total_o_s = 0;
                        this.employee.total_apsen_s = 0;
                        this.employee.total_absent = 0;
                        this.employee.total_present = 0;
                        this.employee.total_fine = 0;
                        this.employee.total_off = 0;
                        this.employee.total_off_s = 0;
                        this.employee.total_s_by_h_work = 0;
                        this.employee.total_final_s = 0;
                        this.employee.gived_salary = 0;
                        this.employee.personal_image = null;
                        this.employee.food_money = 0;
                        this.employee.transport_money = 0;
                        this.employee.cabina_money = 0;
                        this.employee.expense_money = 0;
                        this.employee.fine_money = 0;
                        this.employee.loan_money = 0;
                        this.employee.accomodation_money = 0;
                        this.employee.other_expense = 0;
                        this.employee.other_minus = 0;
                        this.each_days = [];
                        this.each_give_salary = [];
                        this.dollar_price = 0;
                        return "";
                    }
                    this.gs_id = data.gs_id;
                    this.employee.emp_id = data.employee.emp_id || null;
                    this.employee.full_name = data.employee.full_name;
                    this.employee.staff_name = data.employee.staff_name;
                    this.employee.salary_type = data.employee.salary_type;
                    this.employee.personal_image = data.employee.personal_image_path;
                    this.employee.total_worked_hour = data.employee.total_h;
                    this.employee.total_overtime = data.employee.total_o;
                    this.employee.total_fine = data.employee.total_fine;
                    this.employee.total_present = data.employee.count_present;
                    this.employee.total_absent = data.employee.total_apsent
                    this.employee.hour_salary = data.gived_salary.hour_salary || data.employee.hour_salary;
                    this.employee.daily_salary = data.gived_salary.daily_salary || data.employee.daily_salary;
                    this.employee.monthly_salary = data.gived_salary.monthly_salary || data.employee.monthly_salary;
                    this.employee.total_off = data.employee.count_off;
                    this.employee.total_h_not_work = data.employee.total_h_not_work;
                    this.employee.total_o_s = data.employee.total_o_s;
                    this.employee.total_not_work_s = data.employee.total_not_work_s;
                    this.employee.total_apsen_s = data.employee.total_apsen_s;
                    this.employee.total_off_s = data.employee.total_off_s;
                    this.employee.food_money =  data.employee.food_money || data.gived_salary.food_money || 0;
                    this.employee.transport_money = data.employee.transport_money || data.gived_salary.transport_money || 0;
                    this.employee.cabina_money = data.employee.cabina_money || data.gived_salary.cabina_money || 0;
                    this.employee.expense_money = data.employee.expense_money || data.gived_salary.expense_money || 0;
                    this.employee.fine_money = data.employee.fine_money || data.gived_salary.fine_money || 0;
                    this.employee.loan_money = data.employee.loan_money || data.gived_salary.loan_money || 0;
                    this.employee.accomodation_money = data.employee.accomodation_money || data.gived_salary.accomodation_money || 0;
                    this.employee.other_expense = data.employee.other_expense || data.gived_salary.other_expense || 0;
                    this.employee.other_minus = data.employee.other_minus || data.gived_salary.other_minus || 0;
                    this.employee.total_final_s = data.employee.total_final_s - data.employee.total_fine + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus);
                    this.employee.gived_salary = data.employee.gived_salary;
                    this.employee.total_s_by_h_work = (data.employee.total_s_by_h_work + data.employee.total_o_s + data.employee.total_off_s - data.employee.total_fine + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus)).toFixed(3);
                    this.dollar_price = data.employee.dollar_price || this.dollar_price;
                    this.each_days = data.each_days;
                    
                    var d = new Date();
                    d.setMonth(this.month - 1);
                    d.setFullYear(this.year);
                    const allDaysInMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
                    const numberOfFridays = this.fridays(this.year, this.month - 1);
                    const days = allDaysInMonth - numberOfFridays;
                    if(this.employee.salary_type == 'Monthly') {
                        this.employee.daily_salary = Number(this.employee.monthly_salary / days).toFixed(2);
                        this.employee.hour_salary = Number(this.employee.daily_salary / 8).toFixed(2);
                    }
                    
                    this.each_give_salary = data.each_give_salary;
                    
                    this.$http.get('/employee/getEmployeeTransaction/' + this.employee.emp_id).then(({data}) => {
                        this.debt = data.reduce((acc, n) => acc + Number(n.transactionAmount), 0);
                    });

                });

            },
            fridays(year, month) {
                var day, counter, date;
                day = 1;
                counter = 0;
                date = new Date(year, month, day);
                while (date.getMonth() === month) {
                    if (date.getDay() === 6) { // Sun=0, Mon=1, Tue=2, etc.
                        counter += 1;
                    }
                    day += 1;
                    date = new Date(year, month, day);
                }
                return counter;
            },
            create_monthly_receipt() {
                if(this.phone.length == 11){
                    if(this.dollar_price <= 0 && this.employee.salary_type == 'Monthly'){
                        this.$alert("Enter Dollar price", "", "warning");
                    } else {
                        this.$confirm(`دڵنیای لە دروست کردنی وەصڵی مانگی ${this.month}/${this.year}`, "", "question").then(() => {
                            this.$http.post("/gived_salary/addGiveSalary", {
                                emp_id: this.employee.emp_id,
                                salary_month: this.month,
                                salary_year: this.year,
                                monthly_salary: this.employee.monthly_salary,
                                daily_salary: this.employee.daily_salary,
                                hour_salary: this.employee.hour_salary,
                                dollar_price: this.dollar_price,
                                food_money: this.employee.food_money,
                                transport_money: this.employee.transport_money
                            }).then(({data}) => {
                                this.gs_id = data.gs_id;
                                this.$alert("", "Success", "success");
                                this.$http.post('/employee/getEmployeeInfo/'+this.phone+'/'+this.month+'/'+this.year).then(({data})=>{
                                    this.employee.total_fine = data.employee.total_fine;
                                    // this.employee.total_final_s = data.employee.total_final_s - data.employee.total_fine + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus);
                                    // this.employee.total_s_by_h_work = (data.employee.total_s_by_h_work + data.employee.total_o_s + data.employee.total_off_s - data.employee.total_fine + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus)).toFixed(3);
                                });
                            }).catch((err) => {
                                showError(err);
                            });
                        });
                    }
                } else {
                    this.$alert("Enter valid phone number", "Invalid phone number", "warning");
                }
            },
            give_salary() {
                if(this.salary > 0){
                    this.$confirm("دڵنیای لە پێدانی ئەم بڕە پارەیە بۆ کارمەند ؟", "", "question").then(() => {
                        document.getElementById("btn_give_salary").style.display = 'none';
                        this.$http.post("/gived_salary/addGivedDetail", {
                            gs_id: this.gs_id,
                            gived_salary: this.salary
                        }).then(() => {
                            this.each_give_salary.push({
                                gived_salary: this.salary,
                                gived_date: new Date().toISOString().split("T")[0]
                            });
                            this.employee.gived_salary += Number(this.salary);
                            this.salary = 0;
                            this.$alert("Salary gived", "Success", "success");
                        }).catch((err) => {
                            showError(err);
                        }).finally(() => {
                            var btn = document.getElementById("btn_give_salary");
                            if(btn){
                                btn.style.display = '';
                            }
                        });
                    });
                } else {
                    this.$alert("بڕی مووچە ئەبێت گەورەتربێت لە 0", "", "warning");
                }
            },
            search_staff(){
                this.$http.post('/employee/getEmployeeBystaff/'+this.staff_id + '/' + this.month + '/' + this.year).then(([data])=>{
                    this.employees_btn.full_name=data.full_name;
                })
            },
            get_actived() {
                document.getElementById('btn-actived-employees').setAttribute('disabled', 'disabled');
                this.employees = [];
                this.$http.post("/employee/getEmployeeBystaff/" + this.staff_id + '/' + this.month + '/' + this.year).then(({data}) => {
                    this.employees = data;
                }).finally(() => document.getElementById('btn-actived-employees').removeAttribute('disabled'));
            },
            get_deactived() {
                document.getElementById('btn-deactived-employees').setAttribute('disabled', 'disabled');
                this.employees = [];
                this.$http.post("/employee/getDeactivedEmployeeBystaff/" + this.staff_id).then(({data}) => {
                    this.employees = data;
                }).finally(() => document.getElementById('btn-deactived-employees').removeAttribute('disabled'));
            }
        },
        filters: {
            gived_status_filter(value) {
                if(value == 1){
                    return "بـەڵێ";
                } 
                return "نـەخـێـر";
            },
            absent_filter(value, language) {
                return [
                    givedSalaryLanguage[language].present,
                    givedSalaryLanguage[language].absent,
                    givedSalaryLanguage[language].off,
                ][value];
            },
            money_filter(value) {
                if(typeof value == "undefined"){
                    return 0;
                }
                var num = [];
                var point = "";
                value = value.toString();
                if(value.includes('.')){
                    point = value.substring(value.indexOf('.'));
                    num = value.substring(0, value.indexOf('.')).split("").reverse();
                } else {
                    num = value.split("").reverse();
                }
                var arr = [];
                for (let i = 0; i < num.length; i++) {
                    arr.unshift(num[i]);
                    if((i + 1) % 3 == 0){
                        arr.unshift(",");
                    }
                }
                if(point == ".000"){
                    point = "";
                }
                var num_with_comma = arr.join("") + point;
                if(num_with_comma.charAt(0) == ','){
                    return num_with_comma.slice(1);
                }
                return num_with_comma;
            },
            date_filter(value) {
                var d = new Date(value);
                d.setDate(d.getDate() + 1);
                return d.toISOString().split("T")[0];
            }
        },
        watch: {
            emp_id(value) {
                if(value){
                    this.search();
                }
            },
            // phone(value) {
            //     if(value.length == 11){
            //         this.search();
            //     }
            // },
            month() {
                if(this.phone.length == 11){
                    this.search();
                }
            }, 
            year(){
                if(this.phone.length == 11){
                    this.search();
                }
            },
            staff_id(value){
                this.employees = [];
                if(value){
                    this.$http.post("/employee/getEmployeeBystaff/" + this.staff_id + '/' + this.month + '/' + this.year).then(({data}) => {
                        this.employees = data;
                    });
                } else {
                    this.employees = [];
                }
            }
        },
        computed: {
            valid_give_salary() {
                if(this.employee.salary_type == 'Daily'){
                    if(this.employee.total_s_by_h_work > this.employee.total_final_s){
                        return this.salary <= Number((Number(this.employee.total_s_by_h_work) - Number(this.employee.gived_salary)).toFixed(3)) + Number(1000);
                    }
                    return this.salary <= Number((Number(this.employee.total_final_s) - Number(this.employee.gived_salary)).toFixed(3)) + Number(1000);
                } else {
                    if(this.employee.total_s_by_h_work > this.employee.total_final_s){
                        return this.salary <= Number((Number(this.employee.total_s_by_h_work) - Number(this.employee.gived_salary)).toFixed(3)) + Number(1);
                    }
                    return this.salary <= Number((Number(this.employee.total_final_s) - Number(this.employee.gived_salary)).toFixed(3)) + Number(1);
                }
            },
            last_salary() {
                if(this.employee.salary_type == 'Monthly'){
                    return ((this.employee.monthly_salary - this.employee.total_fine - (this.employee.daily_salary * this.employee.total_absent) - this.employee.total_not_work_s + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus) + this.employee.total_o_s )).toFixed(3);
                } else {
                    return (((this.employee.daily_salary * this.employee.total_present) - this.employee.total_fine - this.employee.total_not_work_s + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus) + this.employee.total_o_s )).toFixed(3);
                }
            },
            now_salary() {
                if(this.base_26 && this.employee.salary_type == 'Monthly'){
                    var salary = (((this.employee.daily_salary * this.employee.total_present) - this.employee.total_fine - this.employee.total_not_work_s + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus) + (this.employee.hour_salary * this.employee.total_overtime))).toFixed(3);
                    if(this.each_days.length > 26){
                        var day = this.each_days.length - 26;
                        salary -= (this.employee.daily_salary * day);
                    }
                    return salary;
                } else if (this.employee.salary_type == 'Daily') {
                    return (((this.employee.daily_salary * this.employee.total_present) - this.employee.total_fine - this.employee.total_not_work_s + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus) + (this.employee.hour_salary * this.employee.total_overtime) )).toFixed(3);
                } else {
                    return (((this.employee.daily_salary * this.employee.total_present) - this.employee.total_fine - this.employee.total_not_work_s + Number(this.employee.food_money) + Number(this.employee.transport_money) + Number(this.employee.cabina_money) + Number(this.employee.expense_money) + Number(this.employee.fine_money) + Number(this.employee.loan_money) + Number(this.employee.accomodation_money) + Number(this.employee.other_expense) + Number(this.employee.other_minus) + (this.employee.hour_salary * this.employee.total_overtime) )).toFixed(3);
                }
            }
        }
    }
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .no-print-border {
            border: none !important;
            margin-top: 2px !important;
        }
    }
</style>